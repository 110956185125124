@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,900');

body {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;

}

header {
  padding: 1.06rem 1rem;
  background-color: #fff;
  height: 5vh;
  margin-top: -0.63rem;
  margin-right: -0.63rem;
  margin-left: -0.94rem;
  margin-bottom: 1.25rem;
}

header .logo {
  max-width: 100%;
  transform: translate(-11rem, -0.94rem);
  height: 9vh;
}

.logo-title {
  float: left;
  font-weight:900;
  transform: translate(9.38rem, 0.19rem);
  max-width: 100%;
}

header a {
  text-decoration: none;
  color: black;
}

.menu {
  float:right;
}

.menu a {
  color: black;
  text-decoration:none;
  display:inline-block;
  padding: 0.63rem 1.25rem;
  border-radius: 0.19rem;
  transition: all 0.3s ease-in-out;
  cursor:pointer;
  font-weight: bold;
}

.menu a:hover {
  background: #2197f4;
  color: #fff;
  cursor:pointer;
}

.container {
  background-color: white;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #2197f4;
  
}

h2 {
  text-align: center;
}

p {
  text-align: center;
}

.matrix-dimensions {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.matrix-dimensions label {
  margin-right: 20px;
}

input {
  padding: 5px;
  margin-left: 5px;
  width: 60px;
}

.matrix-input {
  text-align: center;
  margin-bottom: 20px;
}

.matrix-row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.matrix-cell {
  width: 50px;
  padding: 10px;
  margin-right: 5px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ccc;
}

.calculate-button {
  text-align: center;
  margin-bottom: 20px;
}

.calculate-button button {
  font-family: "Poppins", sans-serif;
  background-color: #2197f4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
}

button:hover {
  background: #000;
}

.result-matrix {
  text-align: center;
  margin-bottom: 20px;
}

.result-row {
  display: flex;
  justify-content: center;
}

.result-cell {
  width: 50px;
  padding: 10px;
  margin-right: 5px;
  text-align: center;
  border: 1px solid black;
  font-size: 16px;
}

.calculation-steps {
  text-align: center;
}

.calculation-steps ol {
  display: inline-block;
  text-align: left;
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

.matrix-table {
  margin: 10px auto;
  border-collapse: collapse;
}

.matrix-table td {
  border: 1px solid #2197f4;
  padding: 10px;
  min-width: 50px;
  text-align: right;
}

.solution {
  color: #2197f4;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 52vh;
  text-align: center;
  margin: 1rem;
  line-height: 2rem;
}

.footer-container {
margin-top: 10rem;
margin-bottom: -5rem;
background: #fff;
}

.policy-container {
display:flex;
justify-content: center;
height: 8vh;
border-top: 0.13rem solid #f4f7f9;
}

#footer-1 {
margin-top: 1.63rem;
}

#footer-1 a {
color: #2197f4;
font-weight: bold;
text-decoration: none;
cursor: pointer;

}

#footer-1 a:hover {
text-decoration: none;
}

#footer-2 {
display:flex;
justify-content: center;
color:#000;
padding-bottom: 1rem;

}

@media (min-width:1550px) and (max-width: 3200px) {
  .logo-title{
    transform: translate(17.19rem, 1.25rem);
  }

  header .logo {
    transform: translate(-16.25rem, -7.94rem);
  }
}

@media screen and (max-width: 1500px)  {

  .logo-title {
    float: left;
    transform: translate(11.25rem, 0.63rem);

  } 

  header .logo {
    transform: translate(-16.25rem, -5.31rem);
    height: 30vh;
}
}

@media screen and (max-width:1498px) {
    .container {
      display: block;
    }

    header .logo {
      height: 5.63rem;
      width: 6.1rem;
      margin-top: -1.06rem;
      float: left;
      transform: none;
    }

    .logo-title {
      transform: translate(6.06rem, -3.45rem);
    }  

    .controls-container {
      margin: auto;
    }

}

@media screen and (max-width: 768px)  { 

  .menu a 
  {
    display: none;
  }

  .section {
    display: block;
  }

  .policy-container {
    height: 10vh;
    font-size: 0.63rem;
}

#footer-2 {
  font-size: 0.56rem;
}

}